import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaselineRequest,
  BaselineResponse,
  OfferRequest,
  OfferResponse,
  ProgramResponse,
  RegistrationResponse,
  RegistrationViewConfigResponse,
  ScheduleRequest,
  ScheduleResponse
} from '@dr-customer-offers-ui/lib-interfaces';
import {
  BaselineService,
  OfferService,
  ProgramService,
  RegistrationService,
  RegistrationViewConfigService,
  ScheduleService
} from '@dr-customer-offers-ui/lib-services';
import * as moment from 'moment';
import { NgxDropdownOption } from 'ngx-dropdown';
import { DataModel, NgxIntervalDataGridRowModel, NgxIntervalDataGridService, TableDataTypes } from 'ngx-interval-data-grid';
import { Observable, Subject, catchError, combineLatest, finalize, map, merge, of, shareReplay, switchMap, take } from 'rxjs';
import { DayOfWeekModel, FilterSelectionInterface, GroupedData, RegConfig, transformRegConfig } from '../models';
import { UserPreference } from '../models/user-preference';
import { DataModelService } from './data-model.service';
import { DateService } from './date.service';
import { InternalService } from './internal.service';

@Injectable({ providedIn: 'root' })
export class DataViewModelService {
  private refreshOpenTabTrigger$ = new Subject<void>();
  private refreshBaselineTrigger$ = new Subject<void>();
  private refreshScheduleTrigger$ = new Subject<void>();
  private regViewConfigCache: Map<string, Observable<RegConfig | null>> = new Map<string, Observable<RegConfig | null>>();
  constructor(
    private programService: ProgramService,
    private registrationService: RegistrationService,
    private registrationViewConfigService: RegistrationViewConfigService,
    private internalState: InternalService,
    private dateService: DateService,
    private offerService: OfferService,
    private scheduleService: ScheduleService,
    private baselineService: BaselineService,
    private dataModelService: DataModelService,
    private ngxService: NgxIntervalDataGridService
  ) {}

  getProgramsList(): Observable<NgxDropdownOption[] | null> {
    return combineLatest([this.internalState.getUserPreference, this.programService.getPrograms()]).pipe(
      map(([userPreference, programs]) => {
        let programsData: NgxDropdownOption[] = [];
        if (programs && programs instanceof Array) {
          programsData = programs.map((program: ProgramResponse) => {
            const programsMeta: NgxDropdownOption = {
              value: this.getLocalisedLabel(program.display_labels, userPreference?.apiLocale ?? '', 'en_US'),
              id: program.id
            };
            return programsMeta;
          });
        }
        const sortedProgsData = programsData.length ? programsData.sort((a, b) => a.value.localeCompare(b.value)) : null;
        if(sortedProgsData) this.internalState.setSelectedProgram(sortedProgsData[0]);
        return sortedProgsData && sortedProgsData.length ? sortedProgsData : null;
      }),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        return of(null);
      })
    );
  }

  getRegistrationsList(programId: string): Observable<NgxDropdownOption[] | null> {
    return combineLatest([this.internalState.getUserPreference, this.registrationService.getRegistrations(programId)]).pipe(
      map(([userPreference, registrations]) => {
        if (!registrations || !registrations.length) return null;
        const regsData: NgxDropdownOption[] = registrations.map((registration: RegistrationResponse) => {
          const regsMeta: NgxDropdownOption = {
            value: this.getLocalisedLabel(registration.display_labels, userPreference?.apiLocale ?? '', 'en_US'),
            id: registration.id
          };
          return regsMeta;
        });
        const sortedRegsData = regsData.length ? regsData.sort((a, b) => a.value.localeCompare(b.value)) : null;
        if(sortedRegsData) this.internalState.setSelectedReg(sortedRegsData[0]);
        return sortedRegsData && sortedRegsData.length ? sortedRegsData : null;
      }),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        return of(null);
      })
    );
  }

  getRegistrationsListUsingSession(): Observable<NgxDropdownOption[] | null> {
    return combineLatest([this.internalState.getUserPreference, this.registrationService.getRegistrationsUsingSession()]).pipe(
      map(([userPreference, registrations]) => {
        if (!registrations || !registrations.length) return null;
        const regsData: NgxDropdownOption[] = registrations.map((registration: RegistrationResponse) => {
          const regsMeta: NgxDropdownOption = {
            value: this.getLocalisedLabel(registration.display_labels, userPreference?.apiLocale ?? '', 'en_US'),
            id: registration.id
          };
          return regsMeta;
        });
        const sortedRegsData = regsData.length ? regsData.sort((a, b) => a.value.localeCompare(b.value)) : null;
        if(sortedRegsData) this.internalState.setSelectedReg(sortedRegsData[0]);
        return sortedRegsData && sortedRegsData.length ? sortedRegsData : null;
      }),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        return of(null);
      })
    );
  }

  getRegViewConfig(selectedRegistrationId: string): Observable<RegConfig | null> {
    const cachedObservable: Observable<RegConfig | null> | undefined = this.regViewConfigCache.get(selectedRegistrationId);

    if (cachedObservable) return cachedObservable;

    const vm: Observable<RegConfig | null> = combineLatest([
      this.internalState.getUserPreference,
      this.registrationViewConfigService.getRegistrationViewConfig(selectedRegistrationId)
    ]).pipe(
      map(([userPreference, regConfigResponse]: [UserPreference | null, RegistrationViewConfigResponse]) => {
        if (!regConfigResponse) return null;
        const transformedConfig: RegConfig = transformRegConfig(regConfigResponse, userPreference?.apiLocale ?? '');
        this.internalState.setRegViewConfigState(transformedConfig);
        return transformedConfig;
      }),
      shareReplay(1),
      catchError((error: Error | HttpErrorResponse) => {
        console.warn('Error Loading Org', error);
        this.regViewConfigCache.delete(selectedRegistrationId);
        return of(null);
      }),
      finalize(() => {
        this.regViewConfigCache.delete(selectedRegistrationId);
      })
    );

    this.regViewConfigCache.set(selectedRegistrationId, vm);
    return vm;
  }

  getGroupedDataWithOffers(): Observable<GroupedData | null> {
    return merge(
      this.refreshOpenTabTrigger$.pipe(
        // Switch to the latest value of filterSelection$ upon refresh trigger
        switchMap(() => this.internalState.getFilterSelectionState.pipe(take(2)))
      ),
      this.internalState.getFilterSelectionState // Continues to emit changes to the filter selection
    ).pipe(
      switchMap((filteredData: FilterSelectionInterface | null) => {
                if (!filteredData) return of(null);
        return this.getRegViewConfig(filteredData.registrationId).pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig) {
              return of(null);
            }
            const selectedDatesISO = this.dateService.getISODate(filteredData.dateRange, regConfig.timeZone);
            return this.offerService.getOffers(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end).pipe(
              map((offers: OfferResponse[] | null) => {
                const offerData: DataModel[] = this.dataModelService.getGridDataFromOffers(
                  offers as OfferResponse[],
                  regConfig,
                  selectedDatesISO.start,
                  selectedDatesISO.end
                );

                // Set Open Offers state which we use for export functionality.
                this.internalState.setOpenOffersForExport(offerData);

                // Cast the start and end date to remove the error "No overload matches this call."
                const startdate: moment.Moment = moment(filteredData.dateRange.start as moment.Moment);
                const enddate: moment.Moment = moment(filteredData.dateRange.end as moment.Moment);

                const dayOfWeek: DayOfWeekModel = {
                  data: offerData,
                  timeZone: regConfig.timeZone,
                  startDate: startdate.utc(),
                  endDate: enddate.utc(),
                  intervalFrequency: regConfig.intervalFrequency,
                  tableType: TableDataTypes.OPEN
                };

                this.internalState.setDayOfWeekDataCache(dayOfWeek);
                const groupedData = this.ngxService.groupByDayOfWeek(
                  dayOfWeek.data,
                  dayOfWeek.timeZone,
                  dayOfWeek.startDate,
                  dayOfWeek.endDate,
                  dayOfWeek.intervalFrequency,
                  dayOfWeek.tableType
                );
                return {
                  values: groupedData,
                  regConfig: regConfig,
                  selectedDateRange: filteredData.dateRange
                };
              })
            );
          })
        );
      })
    );
  }

  getGroupedDataWithSchedule(): Observable<GroupedData | null> {
    return merge(
      this.refreshScheduleTrigger$.pipe(
        // Switch to the latest value of filterSelection$ upon refresh trigger
        switchMap(() => this.internalState.getFilterSelectionState.pipe(take(2)))
      ),
      this.internalState.getFilterSelectionState // Continues to emit changes to the filter selection
    ).pipe(
      switchMap((filteredData: FilterSelectionInterface | null) => {
        if (!filteredData) return of(null);
        return this.getRegViewConfig(filteredData.registrationId).pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig || !regConfig.tab_display.display_schedule_tab) return of(null);
            return this.scheduleService.getSchedule(regConfig.registrationId).pipe(
              map((schedule: ScheduleResponse[]) => {
                const scheduleData: DataModel[] = this.dataModelService.getGridDataFromSchedule(schedule, regConfig);
                //TODO - remove this
                const scheduleFirstIndex: DataModel = scheduleData[0];
                const scheduleLastIndex: DataModel = scheduleData[1];

                const startdate: moment.Moment = moment(scheduleFirstIndex.offer_start_dttm_utc);
                const enddate: moment.Moment = moment(scheduleLastIndex.offer_end_dttm_utc);

                const dayOfWeek: DayOfWeekModel = {
                  data: scheduleData,
                  timeZone: regConfig.timeZone,
                  startDate: startdate.utc(),
                  endDate: enddate.utc(),
                  intervalFrequency: regConfig.intervalFrequency,
                  tableType: TableDataTypes.SCHEDULE
                };

                this.internalState.setDayOfWeekDataCache(dayOfWeek);
                const groupedData = this.ngxService.groupByDayOfWeek(
                  dayOfWeek.data,
                  dayOfWeek.timeZone,
                  dayOfWeek.startDate,
                  dayOfWeek.endDate,
                  dayOfWeek.intervalFrequency,
                  dayOfWeek.tableType
                );
                return {
                  values: groupedData,
                  regConfig: regConfig,
                  selectedDateRange: filteredData.dateRange
                };
              })
            );
          })
        );
      })
    );
  }

  getGroupedDataWithBaseline(): Observable<GroupedData | null> {
    return merge(
      this.refreshBaselineTrigger$.pipe(
        // Switch to the latest value of filterSelection$ upon refresh trigger
        switchMap(() => this.internalState.getFilterSelectionState.pipe(take(2)))
      ),
      this.internalState.getFilterSelectionState // Continues to emit changes to the filter selection
    ).pipe(
      switchMap((filteredData: FilterSelectionInterface | null) => {
        if (!filteredData) return of(null);
        return this.getRegViewConfig(filteredData.registrationId).pipe(
          switchMap((regConfig: RegConfig | null) => {
            if (!regConfig || !regConfig.tab_display.display_baselines_tab) return of(null);
            const selectedDatesISO = this.dateService.getISODate(filteredData.dateRange, regConfig.timeZone);
            return this.baselineService.getBaselines(regConfig.registrationId, selectedDatesISO.start, selectedDatesISO.end).pipe(
              map((baseline: BaselineResponse[]) => {
                const baselineData: DataModel[] = this.dataModelService.getGridDataFromBaseline(
                  baseline,
                  regConfig,
                  selectedDatesISO.start,
                  selectedDatesISO.end
                );
                //TODO - remove this
                const baselineFirstIndex: DataModel = baselineData[0];
                const baselineLastIndex: DataModel = baselineData[1];

                const startdate: moment.Moment = moment(baselineFirstIndex.offer_start_dttm_utc);
                const enddate: moment.Moment = moment(baselineLastIndex.offer_end_dttm_utc);

                const dayOfWeek: DayOfWeekModel = {
                  data: baselineData,
                  timeZone: regConfig.timeZone,
                  startDate: startdate.utc(),
                  endDate: enddate.utc(),
                  intervalFrequency: regConfig.intervalFrequency,
                  tableType: TableDataTypes.BASELINE
                };

                this.internalState.setDayOfWeekDataCache(dayOfWeek);
                const groupedData = this.ngxService.groupByDayOfWeek(
                  dayOfWeek.data,
                  dayOfWeek.timeZone,
                  dayOfWeek.startDate,
                  dayOfWeek.endDate,
                  dayOfWeek.intervalFrequency,
                  dayOfWeek.tableType
                );
                return {
                  values: groupedData,
                  regConfig: regConfig,
                  selectedDateRange: filteredData.dateRange
                };
              })
            );
          })
        );
      })
    );
  }

  postOffers(offers: OfferRequest[], regId: string): Observable<OfferResponse[] | null> {
    return this.offerService.putOffers(regId, offers).pipe(
      map((offers: OfferResponse[] | null) => {
        return offers as OfferResponse[];
      })
    );
  }

  postSchedule(offers: ScheduleRequest[], regId: string, ispost: boolean): Observable<ScheduleResponse[] | null> {
    return !ispost
    ? this.scheduleService.putSchedule(regId, offers).pipe(
      map((offers: ScheduleResponse[]) => {
        return offers as ScheduleResponse[];
      })
    )
    : this.scheduleService.postSchedule(regId, offers).pipe(
      map((offers: ScheduleResponse[]) => {
        return offers as ScheduleResponse[];
      })
    )
  }

  postBaseline(baseline: BaselineRequest[], regId: string): Observable<BaselineResponse[] | null> {
    return this.baselineService.putBaselines(regId, baseline).pipe(
      map((_baseline: BaselineResponse[]) => {
        return _baseline as BaselineResponse[];
      })
    );
  }


  public refreshData(tabName: string): void {
    this.ngxService.clearUnSavedData();
    this.internalState.editMode$.next(false);
    switch(tabName) {
      case 'Open': return this.refreshOpenTabTrigger$.next();
      case 'Schedule': return this.refreshScheduleTrigger$.next();
      case 'Baseline': return this.refreshBaselineTrigger$.next();
    }
  }

  public cancelEditing(dayOfWeekData: DayOfWeekModel): NgxIntervalDataGridRowModel[] {
    return this.ngxService.groupByDayOfWeek(
      dayOfWeekData.data,
      dayOfWeekData.timeZone,
      dayOfWeekData.startDate,
      dayOfWeekData.endDate,
      dayOfWeekData.intervalFrequency,
      dayOfWeekData.tableType
    )
  }

  private getLocalisedLabel(displayLabelMap: { [locale: string]: string }, apiLocale: string, fallbackLocale: string): string {
    if (displayLabelMap[apiLocale] !== undefined) {
      return displayLabelMap[apiLocale];
    } else if (displayLabelMap[fallbackLocale] !== undefined) {
      return displayLabelMap[fallbackLocale];
    } else if (displayLabelMap[Object.keys(displayLabelMap)[0]] !== undefined) {
      return displayLabelMap[Object.keys(displayLabelMap)[0]];
    } else {
      return 'Undefined';
    }
  }

}
