import { state, style, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment-timezone';
import { NgxIntervalDataGridRowModel, WeekDays } from 'ngx-interval-data-grid';
import { Subscription } from 'rxjs';
import { GroupedData } from '../../../shared/models';
import { UIState } from '../../../shared/models/utility';
import { MixPanelService } from '../../../shared/services/mixpanel.service';

@Component({
  selector: 'dr-customer-offers-ui-cleared-table',
  templateUrl: './cleared-tab-table.component.html',
  styleUrls: ['./cleared-tab-table.component.scss'],
  animations: [trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0' })), state('expanded', style({ height: '*' }))])],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearedTabTableComponent implements OnInit, OnDestroy {
  @Input()
  set groupedData(g: GroupedData | null) {
    this._groupedData = g;
    if (!g) return;
    this.timezoneAbbr = moment.tz(g.regConfig ? g.regConfig.timeZone : 'UTC').zoneName();
    this.dataSource.data = g.values ?? [];
    const startDate: Date = new Date((g.selectedDateRange?.start ?? moment(new Date())).format('YYYY-MM-DD'));

    this.dates = [];
    for (let i = 0; i < 7; i++) {
      const newDate = new Date(startDate);
      newDate.setDate(startDate.getDate() + i);
      this.dates.push(newDate.getDate());
    }
  }

  get groupedData(): GroupedData | null {
    return this._groupedData;
  }

  private _groupedData: GroupedData | null = null;
  subs = new Subscription();
  UIState = UIState;
  public dates: number[] = [];
  public timezoneAbbr!: string;

  // Define column headers and fields
  columns: string[] = WeekDays;
  displayedColumns: string[] = ['time_period', ...this.columns];
  serverData: NgxIntervalDataGridRowModel[] = [];
  public dataSource = new MatTableDataSource<NgxIntervalDataGridRowModel>(this.serverData);

  protected expandedRow!: NgxIntervalDataGridRowModel | null;

  constructor(private mixPanelService: MixPanelService) {}

  ngOnInit(): void {
    this.mixPanelService.viewTab('Cleared');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
