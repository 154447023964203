import { GateRules, RegistrationViewConfigResponse, Schedule } from '@dr-customer-offers-ui/lib-interfaces';
import { NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { NgxIntervalDataGridRowModel } from 'ngx-interval-data-grid';
import { MinuteFrequencyOption } from './tab.model';

export interface TabDisplayInterface {
    display_schedule_tab: boolean;
    display_cleared_tab: boolean;
    display_baselines_tab: boolean;
}
export interface RegConfig {
  programId: string;
  productId: string;
  registrationId: string;
  timeZone: string;
  minimumValue: number;
  maximumValue: number;
  lockedOut: boolean;
  intervalFrequency: MinuteFrequencyOption;
  isSet: boolean;
  tab_display: TabDisplayInterface;
  display_labels: string;
  gateRules: GateRules;
}

export interface GroupedData {
  values: NgxIntervalDataGridRowModel[] | null;
  regConfig: RegConfig | null;
  selectedDateRange?: NgxDateRangePickerOutput;
}

function getIntervalFrequency(schedule: Schedule): MinuteFrequencyOption {
  if (schedule.offer_frequency_unit === 'MINUTES') {
    return schedule.offer_frequency as MinuteFrequencyOption;
  } else if (schedule.offer_frequency_unit === 'HOURS') {
    return (schedule.offer_frequency * 60) as MinuteFrequencyOption;
  } else if (schedule.offer_frequency_unit === 'DAYS') {
    return (schedule.offer_frequency * 1440) as MinuteFrequencyOption;
  } else {
    return 60 as MinuteFrequencyOption;
  }
}

export function transformRegConfig(regConfigResponse: RegistrationViewConfigResponse, userLocale: string): RegConfig {
  return {
    programId: regConfigResponse.program_id,
    productId: regConfigResponse.product_id,
    registrationId: regConfigResponse.id,
    timeZone: regConfigResponse.timezone,
    minimumValue: regConfigResponse.minimum_value,
    maximumValue: regConfigResponse.maximum_value,
    lockedOut: regConfigResponse.locked_out,
    intervalFrequency: getIntervalFrequency(regConfigResponse.schedule),
    isSet: regConfigResponse.is_set,
    tab_display: regConfigResponse.tab_display,
    display_labels: regConfigResponse.display_labels[userLocale],
    gateRules: regConfigResponse.gate_rules
  };
}
