import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NgxDateRangePickerOutput } from 'ngx-date-range-picker';
import { DaterangeISO, SelectedDateRange } from '../models/daterange';

@Injectable({ providedIn: 'root' })
export class DateService {
  getISODate(dateRange: NgxDateRangePickerOutput, timezone: string): DaterangeISO {
    // Using moment-timezone to handle date creation and manipulation
    const startDate = moment.tz(dateRange.start, timezone);
    const endDate = moment.tz(dateRange.end, timezone);

    return {
        start: startDate.startOf('week').toISOString(),
        end: endDate.endOf('week').toISOString()
    };
}

    getDaysBetweenDates(dateRange: SelectedDateRange) {
        const days: number[] = [];
        for (let m = dateRange.start; m.isBefore(dateRange.end); m.add(1, 'days')) {
            days.push(m.date());
        }
        return days;
    }
}
