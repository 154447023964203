import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OfferRequest, OfferResponse } from '@dr-customer-offers-ui/lib-interfaces';
import { APIData } from 'common-utils/dist/models/api';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiEndpointsService } from '../api-endpoint/api-endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
  ) { }

  getOffers(registration_id: string, start_dttm: string, end_dttm: string): Observable<OfferResponse[] | null> {
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registration_id + `/offers?start_dttm=` + start_dttm + `&end_dttm=` + end_dttm;
    return this.http.get<APIData<OfferResponse[] | null>>(url, {withCredentials: true}).pipe(
      map((res) => this.extractOffersData(res)),
      catchError(this.handleError),
    );
  }

  putOffers(registration_id: string, offers: OfferRequest[]): Observable<OfferResponse[] | null> {
    console.log("putOffers");
    const { baseUrl } = this.apiService;
    const url = `${baseUrl}/registrations/` + registration_id + `/offers`;
    return this.http.put<APIData<OfferResponse[] | null>>(url, offers, {withCredentials: true}).pipe(
        map((res) => this.extractOffersData(res))
      );
  }

  private extractOffersData(res: APIData<OfferResponse[] | null>): OfferResponse[] | null {
    const payload = res.data;
    return payload as OfferResponse[] | null;
  }

  private handleError(error: Error | HttpErrorResponse) {
    // Log the error if needed
    console.error('Error:', error);
    // Emit null to indicate failure
    return of(null);
  }
}

