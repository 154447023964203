import { Injectable } from '@angular/core';
import { exportFile } from 'common-utils/dist/csv-export';
import * as moment from 'moment';
import { NgxDropdownOption } from 'ngx-dropdown';
import { DataModel } from 'ngx-interval-data-grid';
import { ExportReportOutput } from '../models';

// TODO - export to conf or utils file
const headerTitles: ExportOffersModel = Object.freeze({
  registrationId: 'Registration ID',
  registrationName: 'Registration Name',
  date: 'Date',
  intervalStart: 'Interval Start',
  intervalEnd: 'Interval End',
  offerValue: 'Offer Value',
  optOut: 'Opt Out'
});

// TODO - export to models file
interface ExportOffersModel {
  registrationId: string;
  registrationName: string;
  date: string;
  intervalStart: string;
  intervalEnd: string;
  offerValue: string;
  optOut: string;
}

@Injectable({ providedIn: 'root' })
export class ExportCSVService {

  public exportCSV(reportConf: ExportReportOutput, selectedRegistration: NgxDropdownOption, offer: DataModel[]): void {
    const filteredData: DataModel[] = offer.filter((elem: DataModel) => elem.offer_value && elem.offer_value.kW !== null);
    const sortedData: DataModel[] = filteredData.sort(this.sortByStartDate).filter((elem: DataModel) => {
      const start: moment.Moment = moment(elem.offer_start_dttm_utc);
      const end: moment.Moment = moment(elem.offer_end_dttm_utc);
      return start.isSameOrBefore(reportConf.endMomentDate) && end.isSameOrAfter(reportConf.startMomentDate);
    });

    if (sortedData && selectedRegistration) {
      const dataForCSV = sortedData.map((elem: DataModel) => ({
        registrationId: selectedRegistration.id,
        registrationName: selectedRegistration.value,
        date: moment(elem.offer_start_dttm_utc).format('"ddd, DD MMMM YYYY"'),
        intervalStart: moment(elem.offer_start_dttm_utc).format('H:mm'),
        intervalEnd: moment(elem.offer_end_dttm_utc).format('H:mm'),
        offerValue: elem.offer_value.kW,
        optOut: elem.offer_opt_out_state ? 'Yes' : 'No'
      }));
      exportFile(this.buildDataForCSV(dataForCSV), this.buildUniqueFileName());
    }
  }

  private buildDataForCSV(dataForCSV: ExportOffersModel[]): string {
    // Get the headers (property names)
    const headers: string[] = Object.keys(headerTitles).map((key) => headerTitles[key as keyof ExportOffersModel]);

    // Convert array of objects to CSV
    const csvArray: string[] = [
      headers.join(','), // Header row
      ...dataForCSV.map((obj) => Object.values(obj).join(',')) // Data rows
    ];

    return csvArray.join('\n');
  }

  private buildUniqueFileName(): string {
    const timestamp: number = Date.now();
    const randomId: string = Math.random().toString(36).substring(7);
    const fileName = `csv_export_offers_${timestamp}_${randomId}.csv`;
    return fileName;
  }

  private sortByStartDate = (a: DataModel, b: DataModel) => moment(a.offer_start_dttm_utc).utc().diff(moment(b.offer_start_dttm_utc).utc());
}
