  import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suffixDate',
  standalone: true,
  pure: true
})
export class SuffixDatePipe implements PipeTransform {
  transform(day: number): string {
    switch (day % 10) {
      case 1:
        return day + 'st';
      case 2:
        return day + 'nd';
      case 3:
        return day + 'rd';
      default:
        return day + 'th';
    }
  }
}
