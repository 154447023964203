import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxDropdownOption } from 'ngx-dropdown';
import { DataModel } from 'ngx-interval-data-grid';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { BulkInput, DayOfWeekModel, FilterSelectionInterface, HeaderButtpnType, RegConfig } from '../models';
import { UserPreference } from '../models/user-preference';

@Injectable({ providedIn: 'root' })
export class InternalService {
  private onChange$ = new BehaviorSubject<HeaderButtpnType | null>(null);
  private bulkInput$ = new BehaviorSubject<BulkInput | null>(null);
  private selectedProgram$ = new BehaviorSubject<NgxDropdownOption | null>(null);
  private selectedReg$ = new BehaviorSubject<NgxDropdownOption | null>(null);
  private regConfigState$ = new BehaviorSubject<null | RegConfig>(null);
  private userPreference$ = new BehaviorSubject<UserPreference | null>(null);
  private filterSelection$ = new BehaviorSubject<FilterSelectionInterface | null>(null);
  private errorLoadingData$ = new BehaviorSubject<boolean>(false);
  private openOffersForExport$ = new BehaviorSubject<DataModel[]>([]);
  public editMode$ = new BehaviorSubject<boolean>(false);

  private dayOfWeekDataCache$ = new BehaviorSubject<DayOfWeekModel[]>([]);

  public setDayOfWeekDataCache(newState: DayOfWeekModel) {
    const currentData = this.dayOfWeekDataCache$.value;
    const index = currentData.findIndex(item => item.tableType === newState.tableType);

    if (index > -1) {
      // Update existing entry
      currentData[index] = newState;
    } else {
      // Add new entry if it doesn't exist
      currentData.push(newState);
    }

    this.dayOfWeekDataCache$.next(currentData);
  }

  get getDayOfWeekDataCache(): Observable<DayOfWeekModel[] | null> {
    return this.dayOfWeekDataCache$.asObservable();
  }

  public setErrorLoadingData(newState: boolean): void {
    this.errorLoadingData$.next(newState);
  }

  get getErrorLoadingData(): Observable<boolean> {
    return this.errorLoadingData$.asObservable();
  }

  // TODO: can be replaced with Signal
  public setOpenOffersForExport(newTabOption: DataModel[]): void {
    this.openOffersForExport$.next(newTabOption);
  }

  get getOpenOffersForExport(): Observable<DataModel[]> {
    return this.openOffersForExport$.asObservable();
  }

  public setFilterSelectionState(newState: FilterSelectionInterface | null): void {
    this.filterSelection$.next(newState);
  }

  get getFilterSelectionState(): Observable<FilterSelectionInterface | null> {
    return this.filterSelection$.asObservable()
  }

  public setRegViewConfigState(newState: null | RegConfig): void {
    this.regConfigState$.next(newState);
  }

  get selectedRegViewConfigFilterState(): Observable<null | RegConfig> {
    return this.regConfigState$.asObservable();
  }


  // Emit "null" and then filter it so the next tab that subscribe to this observable dont apply the same values again
  // Example: edit on open tab, add value for entire day then change to baseline tab, should not execute the applyBulk function automatically
  public setBulkInputs(newState: null | BulkInput): void {
    this.bulkInput$.next(newState);
    this.bulkInput$.next(null);
  }

  // Emit "null" and then filter it so the next tab that subscribe to this observable dont apply the same values again
  // Example: edit on open tab, add value for entire day then change to baseline tab, should not execute the applyBulk function automatically
  get getBulkInputs$(): Observable<BulkInput | null> {
    return this.bulkInput$.asObservable();
  }

  // Emit "null" to this observable it doesn't trigger the same values again
  public setOnChange(newState: null | HeaderButtpnType): void {
    this.onChange$.next(newState);
    this.onChange$.next(null);
  }

  // Emit "null" and then filter it to this observable so it doesn't apply the same values again
  get getOnChange$(): Observable<HeaderButtpnType | null> {
    return this.onChange$.asObservable().pipe(filter((el:HeaderButtpnType | null) => !!el));
  }

  public setSelectedProgram(newState: NgxDropdownOption | null) {
    this.selectedProgram$.next(newState);
  }

  get getSelectedProgram(): Observable<NgxDropdownOption | null> {
    return this.selectedProgram$.asObservable();
  }

  public setSelectedReg(newState: NgxDropdownOption | null) {
    this.selectedReg$.next(newState);
  }

  get getSelectedReg(): Observable<NgxDropdownOption | null> {
    return this.selectedReg$.asObservable();
  }

  public setUserPreference(newState: UserPreference | null) {
    this.userPreference$.next(newState);
  }

  get getUserPreference(): Observable<UserPreference | null> {
    return this.userPreference$.asObservable();
  }

  constructor(private translate: TranslateService) {}
}
