<mat-card>
    <mat-card-content class="card">
            <div class="d-flex flex-row" *ngIf="{ programsData: (programsData$ | async) } as vm">
                <div class="p-2" *ngIf="isProgramListLoaded">
                    <lib-ngx-dropdown class="ngx-dropdown" #ngx_program_dropdown
                      [options]="vm.programsData"
                      [selected]="selectedProgram$ | async"
                      [border]="true"
                      [multiple]="false"
                      [invalid]="false"
                      [required]="false"
                      [disabled]="!isProgramListLoaded"
                      [title]="'Programs'"
                      [hasInternalReset]="false"
                      [hint]="''"
                      [width]="'30rem'"
                      (onChange)="getRegsFromSelectedProgram($event)">
                    </lib-ngx-dropdown>
                </div>
                <div class="p-2">
                    <lib-ngx-dropdown #ngx_reg_dropdown
                      [options]="(registrationsData$ | async)"
                      [selected]="selectedreg$ | async"
                      [border]="true"
                      [multiple]="false"
                      [invalid]="false"
                      [required]="false"
                      [disabled]="false"
                      [title]="'Registration'"
                      [hint]="''"
                      [width]="'30rem'"
                      [hasInternalReset]="false"
                      (onChange)="selectedRegistration($event)">
                    </lib-ngx-dropdown>
                </div>
                <div class="p-2">
                    <lib-ngx-date-range-picker #ngx_drp
                        [datePickerType]="'weekPicker'"
                        [title]="'Time period'"
                        [border]="true"
                        [isHint]="false"
                        [disabled]="false"
                        [invalid]="false"
                        [disableInputOnly]="true"
                        (onChange)="weekPickerChange($event)">
                    </lib-ngx-date-range-picker>
                </div>
                <div class="p-3">
                  <button
                   (click)="apply()"
                   [disabled]="!filterSelectedObj.registrationId"
                   mat-flat-button
                   color="primary"
                   class="round-button apply-btn">{{'COM.BUTTONS.APPLY' | translate}}</button>
                </div>
            </div>
    </mat-card-content>
</mat-card>
