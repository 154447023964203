<div class="section">
    <div class="section-in">
        <div class="section-in-left">
            <!-- Left Section Content -->
            <h1>{{'APP.TITLE' | translate}}</h1>
        </div>
        <div class="section-in-right section-btns" *ngIf="{editMode: editMode$ | async} as vm">
            <button mat-stroked-button color="primary" class="round-button section-btns--edit" *ngIf="showEditButton && !vm.editMode" (click)="onEdit()">{{'COM.BUTTONS.EDIT' | translate}}</button>
            <button *ngIf="showEditButton && vm.editMode"
                    [disabled]="(disableConfirmButton$ | async) !== true"
                    mat-flat-button
                    color="primary"
                    class="round-button section-btns--confirm"
                    (click)="onConfirm()">{{'COM.BUTTONS.CONFIRM' | translate}}</button>
            <button mat-stroked-button color="primary" class="round-button section-btns--cancel" *ngIf="showEditButton && vm.editMode" (click)="onCancel()">{{'COM.BUTTONS.CANCEL' | translate}}</button>
        </div>
    </div>
</div>
