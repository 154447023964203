import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgxIntervalDataGridService } from 'ngx-interval-data-grid';
import { Observable } from 'rxjs';
import { HeaderButtonTypeEnum } from '../../shared/models';
import { InternalService } from '../../shared/services/internal.service';

@Component({
  selector: 'dr-customer-offers-ui-headboard',
  templateUrl: './headboard.component.html',
  styleUrls: ['./headboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadboardComponent {
  @Input() showEditButton = false;
  disableConfirmButton$: Observable<boolean> = this.ngxService.disableSave$;

  editMode$: Observable<boolean> = this.internalService.editMode$;

  constructor(private ngxService: NgxIntervalDataGridService, private internalService: InternalService) {}

  onEdit() {
    this.internalService.editMode$.next(true);
  }

  onConfirm() {
    this.internalService.setOnChange(HeaderButtonTypeEnum.CONFIRM);
    // Moved to save() on each table
    // this.internalService.editMode$.next(false);
  }

  onCancel() {
    this.internalService.setOnChange(HeaderButtonTypeEnum.CANCEL);
    this.internalService.editMode$.next(false);
  }
}
